@import 'bootstrap';
// @import 'bootstrap-select';
@import 'camera';
@import 'menu';
@import 'font-awesome.min';
@import 'owl.carousel';
@import 'owl.theme';
@import 'animate';
@import 'hover';
@import 'flaticon';
// @import 'jquery.fancybox.min';
@import 'jquery-ui.min';
@import 'style';
@import 'responsive';
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,900|Poppins:300,400,500,600');



