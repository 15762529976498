/* Trim */
.hvr-trim {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
.hvr-trim:before {
  content: '';
  position: absolute;
  border: white solid 3px;
  border-radius: 28px;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  opacity: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.hvr-trim:hover:before, .hvr-trim:focus:before, .hvr-trim:active:before {
  opacity: 1;
}


/* Trim */
.hvr-trim-two {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
.hvr-trim-two:after {
  content: '';
  position: absolute;
  border: white solid 2px;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  opacity: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.hvr-trim-two:hover:after, .hvr-trim-two:focus:after, .hvr-trim-two:active:after {
  opacity: 1;
}


/* Icon Wobble Horizontal */
@-webkit-keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }

  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }

  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.hvr-icon-wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  padding-right: 2.2em;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-wobble-horizontal i {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-wobble-horizontal:hover i, .hvr-icon-wobble-horizontal:focus i, .hvr-icon-wobble-horizontal:active i {
  -webkit-animation-name: hvr-icon-wobble-horizontal;
  animation-name: hvr-icon-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}


@keyframes hvr-ripple-out {
  0% {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    opacity: 1;
  }
  100% {
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    opacity: 0;
  }
}
@keyframes hvr-ripple-out-two {
  0% {
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
    opacity: 1;
  }
  100% {
    top: -20px;
    right: -20px;
    bottom: -20px;
    left: -20px;
    opacity: 0;
  }
}